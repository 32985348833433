import { gql } from "@apollo/client";

export const MUTATION_ADD_CONNECTION = gql`
mutation AddConnection($accountId: String!, $connectionId: String!) {
  addConnection(accountId: $accountId, connectionId: $connectionId) {
    mentorId
    id
  }
}
`;

export const MUTATION_TRANSFER_CONNECTION = gql`
mutation TransferConnection($oldAccountId: String!, $newAccountId: String!, $accountIdToBeTransferred: String!) {
  transferConnection(oldAccountId: $oldAccountId, newAccountId: $newAccountId, accountIdToBeTransferred: $accountIdToBeTransferred) {
    id
    mentorId
  }
}
`;