import { Progress } from '@chakra-ui/react';
import { useAuth } from 'contexts/AuthContext';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

function LogOutCentered(){
  const { logout } = useAuth();
  const history = useHistory();

  useEffect(() => {
    const handleLogout = async () => {
      await logout();
      history.replace('/');
    };

    handleLogout();
  }, [logout, history]);

  return <Progress size='xs' isIndeterminate />;
};

export default LogOutCentered;
