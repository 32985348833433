import { Icon } from "@chakra-ui/react";
import { MdHome, MdLock } from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import MentorshipCentered from "views/admin/default/mentorship";
import LogOutCentered from "views/auth/logOut";

// Auth Imports
import SignInCentered from "views/auth/signIn";

const routes = [
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  {
    name: "Log Out",
    layout: "/auth",
    path: "/log-out",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: LogOutCentered,
  },
  {
    name: "Mentorship",
    layout: "/admin",
    path: "/mentorship",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: MentorshipCentered,
  },
  {
    name: "Main Dashboard",
    protected: true,
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
];

export default routes;
