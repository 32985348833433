import { ApolloProvider } from "@apollo/client";
import { ChakraProvider } from "@chakra-ui/react";
import client from "apollo";
import { AuthProvider } from "contexts/AuthContext";
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import "./assets/css/App.css";
import AdminLayout from "./layouts/admin";
import AuthLayout from "./layouts/auth";
import theme from "./theme/theme";
import { ActivityIndicatorProvider } from "./hooks/useActivityIndicator";

ReactDOM.render(
  <ActivityIndicatorProvider>
  <ChakraProvider theme={theme}>
    <ApolloProvider client={client}>
        <React.StrictMode>
          <AuthProvider>
          <HashRouter>
            <Switch>
              <Route path={`/auth`} component={AuthLayout} />
              <Route path={`/admin`} component={AdminLayout} />
              <Redirect from="/" to="/admin" />
            </Switch>
          </HashRouter>
          </AuthProvider>
        </React.StrictMode>
      </ApolloProvider>
    </ChakraProvider>
  </ActivityIndicatorProvider>,
  document.getElementById("root")
);
