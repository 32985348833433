import { FC } from 'react'
import {
  Text,
  Avatar,
  Flex,
  Box,
} from '@chakra-ui/react'
import { Card as BoardCard, UncontrolledBoardProps } from '@caldwell619/react-kanban'
import Card from "components/card/Card";

interface TaskCardProps {
  title?: string
  description?: string
  id: number | string
  photoURL?: string
}

export const KanBanTaskCard: FC<BoardCard> = ({
  title,
  description,
  id,
  photoURL,
}: TaskCardProps = {
  title: 'John Doe',
  description: 'Implement new feature',
  id: 'TASK-123',
  photoURL: '',
}) => {
  return (
    <Box m={2}>
      <Card width="300px" boxShadow="md">
        <Box>
        <Text fontWeight="bold" mb={2}>
          {title}
        </Text>
        <Text fontSize="sm" color="gray.600" mb={4}>
          {description}
        </Text>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            {/* <Avatar size="xs" name={title} src={''} mr={2} /> */}
            <Text fontSize="xs" color="gray.500">
              {id}
            </Text>
          </Flex>
          <Flex alignItems="center">
            <Avatar
              size="sm"
              name={`Assignee ${id}`}
              src={photoURL}
            />
          </Flex>
        </Flex>
      </Box>
    </Card>
    </Box>
  )
}

export const renderCard: UncontrolledBoardProps<BoardCard>['renderCard'] = card => {
  return <KanBanTaskCard {...card} />
}