import { Card as BoardCard, KanbanBoard, UncontrolledBoard } from "@caldwell619/react-kanban";
import { Box, Flex, SimpleGrid, Text, useToast } from "@chakra-ui/react";
import { renderColumnHeader } from "./components/ColumnHeader";
import Card from "components/card/Card";
import { AccountDto, ConnectionDto } from "types/graphql-types";
import { useMemo, useState, useEffect, useCallback } from "react";
import { renderCard } from "./components/KanBanTaskCard";
import { useActivityIndicator } from "hooks/useActivityIndicator";
import { useMentorsData } from "hooks/useMentorsData";
import { useStudentsData } from "hooks/useStudentsData";
import { useAddConnection } from "hooks/useAddConnection";
import { useTransferConnection } from "hooks/useTransferConnection";

function MentorshipCentered(){
  const [ board, setBoard ] = useState<KanbanBoard<any> | null>(null)
  const { setIsLoading, isLoading } = useActivityIndicator();
  const toast = useToast()
  const { data: mentorsData, loading: mentorsLoading, refetch: refetchMentors } = useMentorsData()
  const { data: studentsData, loading: studentsLoading, refetch: refetchStudents } = useStudentsData()

  const [addConnection, { loading: addConnectionLoading }] = useAddConnection()
  const [transferConnection, { loading: transferConnectionLoading }] = useTransferConnection()
  
  const renderCardDescription = (student: AccountDto) => {
    return (
      <Box>
        <Text>{student.profile.phoneNumber}</Text>
      </Box>
    )
  }

  const unmatchedStudents: AccountDto[] = useMemo(() => [], [])
  const unmatchedStudentsIds: string[] = useMemo(() => [], [])
  const _buildCoulumns = useCallback(() => {
    const resultColumns = mentorsData?.getMentors.map((mentor: AccountDto) => {
      const connections = mentor.accountConnections?.map((connection: ConnectionDto) => connection?.connectedWith?.id)

      const students = studentsData?.getStudents.filter((student: AccountDto) => connections?.includes(student.id)) ?? []

      const unmatchedColumnStudents = studentsData?.getStudents.filter((student: AccountDto) => !connections?.includes(student.id) && !unmatchedStudentsIds.includes(student.id)) ?? []
  

      unmatchedStudents.push(...unmatchedColumnStudents)
      unmatchedStudentsIds.push(...unmatchedColumnStudents.map((student: AccountDto) => student.id))

      return{
        id: mentor.id,
        title: mentor.profile.displayName,
        cards: students.map((student: AccountDto) => ({
          id: student.id,
          title: student.profile.displayName,
          description: student.profile.description,
        })),
      }
    }) ?? []
    resultColumns.unshift({
      id: "unmatched",
      title: "Unmatched",
      cards: unmatchedStudents.map((student: AccountDto) => ({
        id: student.id,
        title: student.profile.displayName,
        description: student.profile.description || '', // Use a string instead of JSX
        photoURL: student?.profile?.photoURL,
      })),
    })
    
    return resultColumns;
  }, [mentorsData?.getMentors, studentsData?.getStudents, unmatchedStudents, unmatchedStudentsIds]);

  useEffect(() => {
    const resultColumns = _buildCoulumns()
    setBoard({
      columns: resultColumns,
    })
    setIsLoading(false)
  }, [_buildCoulumns, mentorsData?.getMentors, setIsLoading, studentsData?.getStudents, unmatchedStudents, unmatchedStudentsIds])

  const handleCardMove: any = (_board: KanbanBoard<BoardCard>, _card: BoardCard, source: any, destination: any) => {
    if(destination.toColumnId === "unmatched") {
      toast({
        title: 'Cannot move card.',
        description: "You cannot move cards to the unmatched column.",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return false;
    }

    if(source.fromColumnId !== "unmatched") {
      return transferConnection({
        variables: {
          oldAccountId: source.fromColumnId,
          newAccountId: destination.toColumnId,
          accountIdToBeTransferred: _card.id,
        }
      })
      .then(() => Promise.all([setIsLoading(true), refetchMentors(), refetchStudents()]))
      .catch((error: any) => {
        console.error("Error transferring connection", error)
      })
    }
    if(source.fromColumnId === "unmatched") {
      return addConnection({
        variables: {
          accountId: _card.id,
          connectionId: destination.toColumnId,
        }
      })
      .then(() => Promise.all([setIsLoading(true), refetchMentors(), refetchStudents()]))
      .catch((error: any) => {
        throw new Error("Error adding connection", error)
      })
    }
  }

  const acquiring = [mentorsLoading, studentsLoading, addConnectionLoading, transferConnectionLoading, !board].some(Boolean)
  
  if(acquiring) {
    setIsLoading(true)
    return <div>Loading...</div>
  } else {
    setIsLoading(false)
  }

  return (
    isLoading ? <div>Loading...</div> : (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }} >
      <Card py="15px">
        <Flex
          direction={"column"}
        >
          <Box>
            <Text fontSize={"2xl"}>
              Controller <small>board</small>
            </Text>
          </Box>
          <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
            <UncontrolledBoard 
              initialBoard={{ ...board }} 
              renderColumnHeader={renderColumnHeader}
              renderCard={renderCard}
              onCardDragEnd={handleCardMove}
              disableColumnDrag
            />
          </SimpleGrid>
        </Flex>
      </Card>
    </Box>
    )
  );
}

export default MentorshipCentered;
