import { gql } from "@apollo/client";

export const QUERY_GET_CODES_BY_MENTOR_ACCOUNT_ID  = gql`
  query GetCodesByMentorAccountId($mentorAccountId: String!, $tenantId: String! ) {
    getCodesByMentorAccountId(mentorAccountId: $mentorAccountId, tenantId: $tenantId) {
      code
      email
      forceConnectionWith
      used
    }
  }
`

export const CREATE_CODE_MUTATION = gql`
mutation CreateCode($createCodeInput: CreateCodeInput!) {
  createCode(createCodeInput: $createCodeInput) {
    id
    code
    tenantId
    forceConnectionWith
    email
    used
  }
}
`


export default QUERY_GET_CODES_BY_MENTOR_ACCOUNT_ID;