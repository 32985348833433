import {
  Avatar,
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  WrapItem,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import Card from "components/card/Card";
import { toLocalizedDateString } from "components/LocalDate";
import * as React from "react";
import { RowObjMentors } from "../variables/tableDataComplex";
import { dateColumns } from "./utils";

const columnHelper = createColumnHelper<RowObjMentors>();

const MentorsTable: React.FC<{ tableData: RowObjMentors[] }> = ({
  tableData,
}) => {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const defaultData = tableData;

  React.useEffect(() => {
    console.log("tableData", tableData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    columnHelper.accessor("photoUrl", {
      id: "photoUrl",
      header: () => renderHeader("PHOTO"),
      cell: (info) => renderAvatarCell(info, textColor),
    }),
    columnHelper.accessor("displayName", {
      id: "displayName",
      header: () => renderHeader("NAME"),
      cell: (info) => renderTextCell(info, textColor),
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: () => renderHeader("EMAIL"),
      cell: (info) => renderTextCell(info, textColor),
    }),
    columnHelper.accessor("major", {
      id: "major",
      header: () => renderHeader("MAJOR"),
      cell: (info) => renderTextCell(info, textColor),
    }),
    columnHelper.accessor("totalUpcomingCalls", {
      id: "totalUpcomingCalls",
      header: () => renderHeader("Upcoming of calls"),
      cell: (info) => renderTextCell(info, textColor),
    }),
    columnHelper.accessor("totalDoneCalls", {
      id: "totalDoneCalls",
      header: () => renderHeader("Completed Calls"),
      cell: (info) => renderTextCell(info, textColor),
    }),
    columnHelper.accessor("totalMentees", {
      id: "totalMentees",
      header: () => renderHeader("number of Students"),
      cell: (info) => renderTextCell(info, textColor),
    }),
    columnHelper.accessor("meanProgression", {
      id: "meanProgressions",
      header: () => renderHeader("Avg. Student Progress"),
      cell: (info) => renderMeanProgressionCell(info, textColor),
    }),
    columnHelper.accessor("lastActivityAt", {
      id: "lastActivityAt",
      header: () => renderHeader("last Access"),
      cell: (info) => {
        const existsValues =
          info.row._valuesCache[info.column.id] !== undefined &&
          info.row._valuesCache[info.column.id] !== null &&
          info.row._valuesCache[info.column.id] !== "undefined" &&
          info.row._valuesCache[info.column.id] !== "null" &&
          info.row._valuesCache[info.column.id] !== "";
        if (dateColumns.includes(info.column.id) && existsValues) {
          info = {
            ...info,
            getValue: () =>
              toLocalizedDateString(
                info.row._valuesCache[info.column.id] as unknown as string
              ) as unknown as any,
          };
        }
        return renderTextCell(info, textColor);
      },
    }),
  ];

  const [data] = React.useState<RowObjMentors[]>(() => [
    ...defaultData,
  ]);
  const table = useReactTable({
    data,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Card
      flexDirection="column"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Mentors
        </Text>
        {/* <Menu /> */}
      </Flex>
      <Box maxH="500px" overflowY="scroll" w="100%">
        {" "}
        {/* Garantir que o Box ocupe 100% da largura */}
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    pe="10px"
                    borderColor={borderColor}
                    cursor="pointer"
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <Flex
                      justifyContent="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {}
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{ asc: "", desc: "" }[
                        header.column.getIsSorted() as string
                      ] ?? null}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    fontSize={{ sm: "14px" }}
                    minW={{ sm: "150px", md: "200px", lg: "auto" }}
                    borderColor="transparent"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
};

const renderHeader = (title: string) => (
  <Text
    justifyContent="space-between"
    align="center"
    fontSize={{ sm: "10px", lg: "12px" }}
    color="gray.400"
  >
    {title}
  </Text>
);

const renderTextCell = (info: any, textColor: string) => (
  <Flex align="center">
    <Text color={textColor} fontSize="sm" fontWeight="700">
      {info.getValue()}
    </Text>
  </Flex>
);

const renderAvatarCell = (info: any, textColor: string) => {
  const name = info.row._valuesCache["displayName"];
  return (
    <Flex align="center">
      <WrapItem>
        <Avatar name={name} src={info.getValue()} />
      </WrapItem>
    </Flex>
  );
};

const renderMeanProgressionCell = (info: any, textColor: string) => (
  <Flex align="center">
    <Text color={textColor} fontSize="sm" fontWeight="700">
      {info.getValue()} calls
    </Text>
  </Flex>
);

export default MentorsTable;
