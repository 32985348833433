import { gql } from "@apollo/client";

export const MUTATION_LOGIN = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      user {
        accountsLinked {
          tenantKey
        }
        name
      }
      accessToken
    }
  }
`;

export const QUERY_GET_ME = gql`
  query Query {
    me {
      currentAccount {
        id
        userId
        profile {
          college
          description
          displayName
          ethnicity
          gradeLevel
          highSchool
          intendedMajor
          interests
          location {
            latitude
            longitude
          }
          major
          majorYear
          minor
          phoneNumber
          photoURL
          videos {
            id
            url
          }
        }
        sendBirdUser {
          userId
          accessToken
        }
        tenantId
      }
      email
      passwordReset
      passwordChanged
      username
      type
    }
  }
`;
