import { Box, IconButton, Text, Badge } from '@chakra-ui/react'
import { Card, UncontrolledBoardProps } from '@caldwell619/react-kanban'
import { CloseIcon } from '@chakra-ui/icons'
import { Link } from 'react-router-dom'

export interface CustomCard extends Card {
  id: string
  assigneeId: number
  storyPoints: number
  prLink?: string
  createdAt: Date
}

export const renderColumnHeader: UncontrolledBoardProps<CustomCard>['renderColumnHeader'] = (
  { title, cards, id },
  { removeColumn }
) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Text variant='subtitle2'>
        <Link to={`/admin/mentor/${id}`}>{title} <Badge>{cards.length}</Badge></Link>
      </Text>
      <IconButton onClick={removeColumn} aria-label='Remove column'>
        <CloseIcon />
      </IconButton>
    </Box>
  )
}