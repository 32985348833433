import { useQuery, useMutation, DocumentNode, QueryHookOptions, MutationHookOptions } from "@apollo/client";
import { useAuth } from "contexts/AuthContext";

export const useTenantQuery = <TData, TVariables>(
  query: DocumentNode,
  options?: Omit<QueryHookOptions<TData, TVariables>, 'context'>
) => {
  const { user } = useAuth();

  return useQuery<TData, TVariables>(query, {
    ...options,
    skip: !user.tenantId || options?.skip,
    context: {
      headers: {
        "x-tenant-id": user.tenantId,
      },
    },
    fetchPolicy: options?.fetchPolicy || "no-cache",
  });
};

export const useTenantMutation = <TData, TVariables>(
  query: DocumentNode,
  options?: Omit<MutationHookOptions<TData, TVariables>, 'context'>
) => {
  const { user } = useAuth();

  return useMutation<TData, TVariables>(query, {
    ...options,
    context: {
      headers: {
        "x-tenant-id": user.tenantId,
      },
    },
    fetchPolicy: options?.fetchPolicy || "no-cache",
  });
};