import { useQuery } from "@apollo/client";
import { jwtDecode } from "jwt-decode";
import React, { createContext, useContext, useEffect, useState } from "react";
import client, { getTokenPayload, TokenPayload } from "../apollo";
import { MUTATION_LOGIN, QUERY_GET_ME } from "../graphql/auth.graphql";

interface User {
  tokenInfo: TokenPayload;
  tenantId: any;
  account?: any;
  profile?: any;
}

interface AuthContextType {
  user: User | null;
  loading: boolean;
  login: (email: string, password: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  loading: true,
  login: (email: string, password: string) => {},
  logout: () => {},
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);

  const { loading: meLoading, data: meData } = useQuery(QUERY_GET_ME, {
    skip: !user?.tenantId,
    context: {
      headers: {
        "x-tenant-id": user?.tenantId ?? localStorage.getItem("tenantId"),
      },
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    const fetchUser = async () => {
      const tokenPayload = getTokenPayload();
      if (tokenPayload) {
        const tenantId = localStorage.getItem("tenantId");
        setUser({
          tokenInfo: tokenPayload,
          tenantId,
          account: meData?.me.currentAccount,
        });
      }
      setLoading(false);
    };

    fetchUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (meData && !meLoading) {
      const tenantId = localStorage.getItem("tenantId");
      setUser({
        tokenInfo: getTokenPayload(),
        tenantId,
        account: meData?.me?.currentAccount,
      });
      setLoading(false);
    }
  }, [meData, meLoading]);

  const login = (identifier: string, password: string) => {
    setLoading(true);
    client
      .mutate({
        mutation: MUTATION_LOGIN,
        variables: {
          input: {
            identifier,
            password,
          },
        },
      })
      .then((response) => {
        const token = response.data.login.accessToken;
        const userResponse = response.data.login.user;
        const tenantId = userResponse.accountsLinked[0].tenantKey;

        localStorage.setItem("token", token);
        localStorage.setItem("tenantId", tenantId);

        const decoded = jwtDecode<TokenPayload>(token);
        setUser({ tokenInfo: decoded, tenantId, profile: userResponse });
        setLoading(false);
      })
      .catch((error) => {
        console.error("Login failed", error);
        setLoading(false);
      });
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("tenantId");
    setUser(null);
    setLoading(false);
  };

  return (
    <AuthContext.Provider value={{ user, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
